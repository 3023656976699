import http from '@/config/http-common';
import Segment from '@/types/Segment';
import Product from '@/types/Product';

class SimulationDataService {
  static execute(segment: Segment, product: Product, form: any): Promise<any> {
    return http.post('/simulation/execute', {
      ...{
        segment: segment.pid,
        product: product.pid,
      },
      ...form,
    });
  }

  static download(simulation: string): Promise<any> {
    return http.post(`/simulation/${simulation}/download`, {}, {
      responseType: 'arraybuffer',
    });
  }

  static getResult(simulation: string): Promise<any> {
    return http.get(`/simulation/${simulation}`);
  }
}

export default SimulationDataService;
