import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db661672"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "w-full bg-gray-200 z-10 top-14 xl:top-0 2xl:top-6 relative transition duration-400 ease-in-out"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "slide" }, {
    default: _withCtx(() => [
      (_ctx.isVisibleProgressBar())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "bg-teal text-xs leading-none py-1 text-center bg-primary text-white",
              style: _normalizeStyle(_ctx.progress())
            }, null, 4)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}