import SegmentDataHelper from '@/helpers/SegmentDataHelper';
import ProductDataService from '@/services/ProductDataService';
import UndefinedResourceError from '@/types/exceptions/UndefinedResourceError';
import Product from '@/types/Product';
import BasicDataHelper from './BasicDataHelper';
import FinanceTypeDataHelper from './FinanceTypeDataHelper';

class ProductDataHelper extends BasicDataHelper {
  static getObjectFromStore(store: any, val: string): Product {
    return ProductDataHelper.searchInTree(store.state.products, val);
  }

  static searchInTree(list: Product[], val: string): Product {
    let product = {} as Product;

    if (list === null || list === undefined) return product;

    Object.keys(list).every((key: any) => {
      if (val === list[key].pid) {
        product = list[key];
        return false;
      }

      product = ProductDataHelper.searchInTree(list[key].children, val);

      if (Object.keys(product).length > 0) return false;

      return true;
    });

    return product;
  }

  static prepareComponentData(store: any, segment: string, financeType: string, successCallback: any = (data: any) => { /* */ }, failCallback: any = (e: any) => { /* */ }): void {
    SegmentDataHelper.prepareComponentData(store, (data: any) => {
      const segmentObj = SegmentDataHelper.getObjectFromStore(store, segment);

      FinanceTypeDataHelper.prepareComponentData(store, segmentObj.pid, (dataFt: any) => {
        const financeTypeObj = FinanceTypeDataHelper.getObjectFromStore(store, financeType);

        if (financeTypeObj === null || financeTypeObj === undefined || !Object.keys(financeTypeObj).length) {
          throw new UndefinedResourceError();
        }

        if (store.state.products.length === 0 || localStorage.getItem('products_filters') === null || localStorage.getItem('products_filters') !== `${segmentObj.pid}${financeTypeObj.pid}${localStorage.isCustomer}`) {
          localStorage.setItem('products_filters', `${segmentObj.pid}${financeTypeObj.pid}${localStorage.isCustomer}`);

          ProductDataService.getFiltered(segmentObj, financeTypeObj, localStorage.isCustomer)
            .then((response: any) => {
              store.dispatch('setListOfProducts', response.data.data);

              successCallback(response.data.data);
            })
            .catch((e: Error) => {
              failCallback(e);
            });
        } else {
          successCallback(store.state.products);
        }
      }, (e: Error) => {
        failCallback(e);
      });
    }, (e: Error) => {
      failCallback(e);
    });
  }
}

export default ProductDataHelper;
