
import { defineComponent } from 'vue';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';
import Navbar from './components/Navbar.vue';
import Sidebar from './components/Sidebar.vue';
import ProgressBar from './components/ProgressBar.vue';
import Modal from './components/Modal.vue';
import ConfigDataService from './services/ConfigDataService';
import AccountDataService from './services/AccountDataService';

export default defineComponent({
  name: 'Main',
  components: {
    BounceLoader,
    Navbar,
    Sidebar,
    ProgressBar,
    Modal,
  },
  data() {
    return {
      events: ['mousedown', 'mousemove', 'keypress'],
      freeScreen: true as boolean,
    };
  },
  created() {
    const vm = this;

    // Load app dynamic configuration
    ConfigDataService.getConfig().then((response: any) => {
      vm.$store.dispatch('setConfiguration', response.data.data);
      vm.$store.dispatch('initTimerLogoutInterval', vm.$store.state.configuration.session_idle_timeout * 60);
    });

    // Initiate user profile
    AccountDataService.getProfile(localStorage.getItem('access_token'))
      .then((responses: any) => {
        vm.$store.dispatch('isLoading', false);
        vm.$store.dispatch('setProfile', responses.data.data);
      });
  },
  mounted() {
    const vm = this;

    this.events.forEach((event) => {
      window.addEventListener(event, () => {
        vm.$store.dispatch('initTimerLogoutInterval', vm.$store.state.configuration.session_idle_timeout * 60);
      });
    }, this);
  },
  methods: {
    isOpenSidebar() {
      return this.$store.state.isOpenSidebar;
    },
    className() {
      const className = !this.isOpenSidebar() ? 'pl-0' : 'pl-0 xl:pl-72 2xl:pl-80';
      return className.concat(['SegmentSelection', 'LoginBase', 'Login', 'ForgotPassword'].includes(this.$route.name as string) ? '' : ' xl:pt-14');
    },
    icon(type: string) {
      if (type === 'success') {
        return `<svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <path d="M0 0h34v34H0z" />
                    <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2.04">
                      <path
                        d="M17 29.75h0C9.945 29.75 4.25 24.042 4.25 17h0C4.25 9.945 9.958 4.25 17 4.25h0c7.042 0 12.75 5.708 12.75 12.75h0c0 7.042-5.708 12.75-12.75 12.75z"
                        stroke="#FFF"
                        fill="#FFF"
                      />
                      <path stroke="#0BB91A" d="m22.667 14.167-7.084 7.083-4.25-4.25" />
                    </g>
                  </g>
                </svg>`;
      }

      if (['warning', 'error'].includes(type)) {
        return `<svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h34v34H0z" />
            <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2.125">
              <path
                d="m19.874 5.864 10.838 18.989c1.26 2.195-.34 4.954-2.875 4.954H6.134c-2.543 0-4.137-2.747-2.874-4.954L14.096 5.85c1.26-2.239 4.476-2.239 5.749 0l.03.013z"
                stroke="#FFF"
                fill="#FFF"
              />
              <path
                stroke="${type === 'warning' ? '#ffa200' : '#e02020'}"
                d="M17 18.587v-5.299M16.999 22.844a.35.35 0 0 0-.355.354.36.36 0 0 0 .355.354.37.37 0 0 0 .354-.354.363.363 0 0 0-.369-.354"
              />
            </g>
          </g>
        </svg>`;
      }

      return `<svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
              <path d="M0 0h34v34H0z"/>
              <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2.125">
                  <path d="M17 29.75h0C9.945 29.75 4.25 24.042 4.25 17h0C4.25 9.945 9.958 4.25 17 4.25h0c7.042 0 12.75 5.708 12.75 12.75h0c0 7.042-5.708 12.75-12.75 12.75z" stroke="#FFF" fill="#FFF"/>
                  <path stroke="#0091FF" d="M17 24.083V17h-1.417M16.632 11.333a.35.35 0 0 0-.355.354.36.36 0 0 0 .355.355.37.37 0 0 0 .354-.354.363.363 0 0 0-.369-.355"/>
              </g>
          </g>
      </svg>`;
    },
    notificationWidth() {
      if (window.innerWidth < 640) {
        return window.innerWidth - 10;
      }

      return 450;
    },
    forceRerender() {
      this.$store.dispatch('updateComponent');
    },
  },
});
