import InputConfig from '@/types/InputConfig';
import AccountDataService from '@/services/AccountDataService';
import BasicDataHelper from './BasicDataHelper';
import SimulationDataHelper from './SimulationDataHelper';

class AccountDataHelper extends BasicDataHelper {
  static getObjectFromStore(store: any, val: string): InputConfig {
    let input = {} as InputConfig;

    Object.keys(store.state.simulation.config).every((key: any) => {
      if (val === store.state.simulation.config[key].name) {
        input = store.state.simulation.config[key];
        return false;
      }

      return true;
    });

    return input;
  }

  static prepareComponentRegister(store: any, simulation: string, successCallback: any = (data: any) => { /* */ }, failCallback: any = (e: any) => { /* */ }): void {
    if (store.state.selected.segment.pid === 'part') {
      if (!Object.keys(store.state.selected.simulation).length || !Object.keys(store.state.selected.segment).length) {
        SimulationDataHelper.prepareComponentResult(store, simulation, (dataSim: any) => {
          successCallback(dataSim);
        }, (e: any) => {
          failCallback(e);
        });
      } else {
        successCallback({
          ...store.state.selected.simulation,
          ...{
            segment: store.state.selected.segment,
            financeType: store.state.selected.financeType,
            product: store.state.selected.product,
          },
        });
      }
    }
  }

  static prepareComponentEmailConfirmation(component: any, successCallback: any = (data: any) => { /* */ }, failCallback: any = (e: any) => { /* */ }): void {
    const token = component.getAccessToken();

    AccountDataService.getProfile(token)
      .then((response: any) => {
        component.$store.dispatch('setProfile', response.data.data);

        successCallback(response.data.data);
      })
      .catch((e: Error) => {
        failCallback(e);
      });
  }

  static removeTokens(): void {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
  }
}

export default AccountDataHelper;
