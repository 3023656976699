import AccountDataService from '@/services/AccountDataService';

const globals = {
  methods: {
    goToNextScreen(component: any, params: any): void {
      if (Object.keys(component.$store.state.selected.segment).length && component.$options.name in component.$store.state.selected.segment.flows) {
        component.$router.push({
          name: component.$store.state.selected.segment.flows[component.$options.name].next,
          params,
        });
      }
    },
    goToNotFound(component: any): void {
      component.$router.push({
        name: 'Error404',
      });
    },
    goToPreviousScreen(component: any, params: any): void {
      if (Object.keys(component.$store.state.selected.segment).length && component.$options.name in component.$store.state.selected.segment.flows) {
        component.$router.push({
          name: component.$store.state.selected.segment.flows[component.$options.name].previous,
          params,
        });
      } else {
        component.$router.push({
          name: 'SegmentSelection',
        });
      }
    },
    setProfile(store: any, successCallback = (response: any): void => { /* */ }, failCallback = (response: any): void => { /* */ }): void {
      const token = this.getAccessToken();

      if (token !== null) {
        AccountDataService.getProfile(token)
          .then((response: any) => {
            store.dispatch('setProfile', response.data.data);
            successCallback(response.data);
          }).catch((e: any) => {
            if (e.response.code === 401) {
              store.dispatch('setProfile', null);
              store.dispatch('setApiToken', null);
            }

            failCallback(e.response);
          });
      }
    },
    logout(router: any, store: any): void {
      const token = this.getAccessToken();
      AccountDataService.logout(token);

      localStorage.removeItem('refresh_token');
      this.setAccessToken(null);

      store.dispatch('setProfile', null);

      if ((Object.keys(store.state.selected.segment).length && router.currentRoute.value.name in store.state.selected.segment.flows && store.state.selected.segment.flows[router.currentRoute.value.name].restricted) || router.currentRoute.value.name === 'CustomerPanel') {
        router.push({
          name: 'SegmentSelection',
        });
      }
    },
    getAccessToken(): string|null {
      return localStorage.getItem('access_token');
    },
    setAccessToken(token: string|null): void {
      localStorage.setItem('access_token', String(token));
    },
    setRefreshToken(token: string|null): void {
      localStorage.setItem('refresh_token', String(token));
    },
    progress(component: any): void {
      if (component.$options.name in component.$store.state.selected.segment.flows) {
        component.$store.dispatch('progress', component.$store.state.selected.segment.flows[component.$options.name].progress);
      }
    },
    isAuthenticated(store: any, checkEmail = false): boolean {
      // alert(Object.keys(store.state.auth).length);
      return store.state.auth.profile !== null
        && store.state.auth.profile !== undefined
        && Object.keys(store.state.auth.profile).length > 0
        && ((checkEmail && store.state.auth.profile.email_verified) || !checkEmail);
    },
    redirectIfUnauthenticated(router: any, store: any, params: any): void {
      if (!this.isAuthenticated(store)) {
        router.push({
          name: 'SegmentSelection',
          params,
        });
      }
    },
    scrollToTop(): void {
      window.scrollTo(0, 0);
    },
    setStepAsCompleted(component: any, completed: boolean): void {
      const direction = !completed ? 'previous' : 'next';
      component.$store.dispatch('setStepAsCompleted', { step: component.$options.name, completed, direction });
    },
    setNamedStepAsCompleted(component: any, name: string, completed: boolean): void {
      const direction = !completed ? 'previous' : 'next';
      component.$store.dispatch('setStepAsCompleted', { step: name, completed, direction });
    },
    initLocalStorage(): void {
      localStorage.removeItem('isCustomer');
      localStorage.removeItem('financeType');
      localStorage.removeItem('product');
      localStorage.removeItem('product_amount');
      localStorage.removeItem('customer_income');
      localStorage.removeItem('customer_co_income');
      localStorage.removeItem('total_income');
      localStorage.removeItem('age');
      localStorage.removeItem('duration');
      localStorage.removeItem('is_customer');
      localStorage.removeItem('is_saver');
      localStorage.removeItem('other_credit');
      localStorage.removeItem('other_credit_charge');
    },
    /* RECAPTCHA LOADER */
    async recaptcha(component: any) {
      const vm = this;
      /* (optional) Wait until recaptcha has been loaded. */
      await component.$recaptchaLoaded();

      /* Execute reCAPTCHA with action "login". */
      const token = await component.$recaptcha('login');

      /* Do stuff with the received token. */
      component.$store.dispatch('setRecaptcha', token);
    },
  },
};

export default globals;
