import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'SegmentSelection',
    component: () => import('../views/steps/SegmentSelection.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Segment client'),
    },
  }, {
    path: '/:segment',
    name: 'Home',
    component: () => import('../views/steps/Home.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Accueil'),
    },
  }, {
    path: '/:segment/fts',
    name: 'FinanceTypeSelection',
    component: () => import('../views/steps/FinanceTypeSelection.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Types de financement'),
    },
  }, {
    path: '/:segment/isc',
    name: 'IsCustomerSelection',
    component: () => import('../views/steps/IsCustomerSelection.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Êtes-vous client ?'),
    },
  }, {
    path: '/:segment/:financeType/products',
    name: 'ProductSelection',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/steps/ProductSelection.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Produits'),
    },
  }, {
    path: '/:segment/:financeType/:product/simulation',
    name: 'Simulation',
    component: () => import('../views/steps/Simulation.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Simulation'),
    },
  }, {
    path: '/simulation/:simulation/result',
    name: 'Result',
    component: () => import('../views/steps/Result.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Résultat de la simulation'),
    },
  }, {
    path: '/simulation/:simulation/ready',
    name: 'Ready',
    component: () => import('../views/steps/Ready.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Initiation à la création de compte'),
    },
  }, {
    path: '/auth/register/:simulation',
    name: 'Register',
    component: () => import('../views/steps/auth/Register.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Création de compte'),
    },
  }, {
    path: '/auth/register/:simulation/confirmation',
    name: 'EmailHasBeenSent',
    component: () => import('../views/steps/auth/EmailHasBeenSent.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Confirmation de l\'email'),
    },
  }, {
    path: '/auth/register/:simulation/password',
    name: 'DefinePassword',
    component: () => import('../views/steps/auth/DefinePassword.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Définition du mot de passe'),
    },
  }, {
    path: '/auth/login/:simulation',
    name: 'Login',
    component: () => import('../views/steps/auth/Login.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Authentification'),
    },
  }, {
    path: '/file/:file/kyc',
    name: 'KYC',
    component: () => import('../views/steps/KYC.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Informations complémentaires'),
    },
  }, {
    path: '/file/:file/kycprof',
    name: 'KYCProf',
    component: () => import('../views/steps/KYCProf.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Informations complémentaires'),
    },
  }, {
    path: '/file/:file/kyccorp',
    name: 'KYCCorp',
    component: () => import('../views/steps/KYCCorp.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Informations complémentaires'),
    },
  }, {
    path: '/file/:file/finfos',
    name: 'FinanceInfo',
    component: () => import('../views/steps/FinanceInfo.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Informations financières'),
    },
  }, {
    path: '/file/:file/documents',
    name: 'DocumentUpload',
    component: () => import('../views/steps/DocumentUpload.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Téléversement de documents'),
    },
  }, {
    path: '/file/:file/rdv',
    name: 'RDV',
    component: () => import('../views/steps/RDV.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Prise de rendez-vous'),
    },
  }, {
    path: '/file/:file/congratulations',
    name: 'End',
    component: () => import('../views/steps/End.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Finalisation'),
    },
  }, {
    path: '/auth/panel',
    name: 'CustomerPanel',
    component: () => import('../views/Customer/Panel.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Espace client'),
    },
  }, {
    path: '/auth/panel/:file',
    name: 'CustomerFile',
    component: () => import('../views/Customer/File.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Détails de la demande'),
    },
  }, {
    path: '/auth/login',
    name: 'LoginBase',
    component: () => import('../views/steps/auth/Login.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Authentification'),
    },
  }, {
    path: '/auth/forgotpassword/:token?/:email?',
    name: 'ForgotPassword',
    component: () => import('../views/steps/auth/ForgotPassword.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Récupération du mot de passe'),
    },
  }, {
    path: '/notfound',
    name: 'Error404',
    component: () => import('../views/Static/Error404.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Page introuvable'),
    },
  }, {
    path: '/error',
    name: 'Error500',
    component: () => import('../views/Static/Error500.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Erreur de serveur'),
    },
  }, {
    path: '/unauthorized',
    name: 'Error403',
    component: () => import('../views/Static/Error403.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Accés non autorisé'),
    },
  }, {
    path: '/expired',
    name: 'ExpiredLink',
    component: () => import('../views/Static/ExpiredLink.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Lien expiré'),
    },
  }, {
    path: '/auth/congratulations',
    name: 'AccountActivated',
    component: () => import('../views/Static/AccountActivated.vue'),
    meta: {
      title: process.env.VUE_APP_NAME.concat(' - ', process.env.VUE_APP_CLIENT_SHORT_NAME, ' | ', 'Votre compte a été confirmé'),
    },
  }, {
    path: '/:catchAll(.*)',
    redirect: '/notfound',
  },
];

export default routes;
