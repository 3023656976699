import router from '@/router';

export default class UndefinedResourceError extends Error {
  constructor() {
    super();

    router.push({
      name: 'Error404',
    });
  }
}
