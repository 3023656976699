import { createStore } from 'vuex';
import Segment from '@/types/Segment';
import FinanceType from '@/types/FinanceType';
import Product from '@/types/Product';
import ModalData from '@/types/ModalData';
import Profile from '@/types/Profile';
import File from '@/types/File';
import DocumentType from '@/types/DocumentType';
import StepState from '@/types/StepState';
import StepData from '@/types/StepData';
import Simulation from '@/types/Simulation';
import AccountDataService from '@/services/AccountDataService';
import Configuration from '@/types/Configuration';

export default createStore({
  state: {
    apiCallsQueue: [] as any[],
    configuration: {} as Configuration,
    timerLogoutinterval: {
      remaining: null as number|null,
      interval: null as any,
      intervalCheck: null as any,
    },
    recaptcha: '' as string,
    refreshing: false,
    updateComponent: 0,
    segments: [] as Segment[],
    financeTypes: [] as FinanceType[],
    products: [] as Product[],
    documentTypes: [] as DocumentType[],
    auth: {
      profile: null as Profile|null,
    },
    labels: {},
    selected: {
      segment: {} as Segment,
      financeType: {} as FinanceType,
      product: {} as Product,
      simulation: {} as Simulation,
      file: {} as File,
    },
    data: {} as {[key: string]: any},
    isLoading: false,
    progress: 0,
    isOpenSidebar: false,
    isVisibleProgressBar: false,
    isOpenTopbar: true,
    afterLogin: null,
    modal: {
      data: {} as ModalData,
      visible: false,
    },
  },
  mutations: {
    mutateRefreshing(state, value: boolean) {
      state.refreshing = value;
    },
    mutateRecaptcha(state, token: string) {
      state.recaptcha = token;
    },
    mutateUpdateComponent(state) {
      state.updateComponent += 1;
    },
    mutateSelectedSegment(state, value: Segment): void {
      if (!Object.keys(state.selected.segment).length || state.selected.segment.pid !== value.pid || !Object.keys(state.data).length) {
        Object.keys(value.flows).forEach((key: string) => {
          state.data[key] = {
            completed: null,
            data: null,
          } as {[key: string]: any};
        });
      }

      state.selected.segment = value;
    },
    mutateSelectedFinanceType(state, value: FinanceType): void {
      state.selected.financeType = value;
    },
    mutateSelectedProduct(state, value: Product): void {
      state.selected.product = value;
    },
    mutateSelectedSimulation(state, value: Simulation): void {
      state.selected.simulation.inputs = value.inputs;
      state.selected.simulation.outputs = value.outputs;
    },
    mutateLabels(state, value: any) {
      state.labels = value;
    },
    /* PROGRESS BAR MUTATOR */
    mutateProgressValue(state, value: number) {
      state.progress = value;
    },
    mutateAfterLogin(state, value: any) {
      state.afterLogin = value;
    },
    mutateIsLoading(state, value: boolean) {
      state.isLoading = value;
    },
    /* SIDE BAR MUTATOR */
    mutateToggleSideBar(state, value: boolean|null) {
      state.isOpenSidebar = (value === null) ? !state.isOpenSidebar : value;
    },
    mutateToggleVisibleProgressBar(state, value: boolean|null) {
      state.isVisibleProgressBar = (value === null) ? !state.isOpenSidebar : value;
    },
    mutateToggleTopBar(state, value: boolean|null) {
      state.isOpenTopbar = (value === null) ? !state.isOpenTopbar : value;
    },
    /* MODAL */
    modal(state, data: string|ModalData) {
      if (data === 'hide') {
        if ('actions' in state.modal.data && 'cancel' in state.modal.data.actions && Object.keys(state.modal.data.actions.cancel).length) state.modal.data.actions.cancel.callback();
        state.modal.visible = false;
      } else if (data === 'show') {
        state.modal.visible = true;
      } else if (data === 'proceed') {
        if ('actions' in state.modal.data && 'proceed' in state.modal.data.actions && Object.keys(state.modal.data.actions.proceed).length) state.modal.data.actions.proceed.callback();
        state.modal.visible = false;
      } else {
        state.modal.data = data as ModalData;
        state.modal.visible = true;
      }
    },
    mutateDataIntoStep(state, data: StepData) {
      state.data[data.step].data = data.data;
    },
    mutateStepCompleted(state, data: StepState) {
      state.data[data.step].completed = data.completed;
    },
    /* SEGMENTS MUTATORS */
    mutateListOfSegments(state, segments: Segment[]) {
      state.segments = segments;
    },
    /* FINANCE TYPES MUTATORS */
    mutateListOfFinanceTypes(state, financeTypes: FinanceType[]) {
      state.financeTypes = financeTypes;
    },
    /* PRODUCTS MUTATORS */
    mutateListOfProducts(state, products: Product[]) {
      state.products = products;
    },
    /* ACCOUNT MUTATORS */
    mutateProfile(state, data: Profile) {
      state.auth.profile = data;
    },
    /* FILE MUTATORS */
    mutateSelectedFile(state, data: File) {
      state.selected.file = data;
    },
    /* DOCUMENT TYPES MUTATORS */
    mutateDocumentTypes(state, data: DocumentType[]) {
      state.documentTypes = data;
    },
    mutateConfiguration(state, value: Configuration) {
      state.configuration = value;
    },
    mutateTimerLogoutIntervalRemaining(state, value: number) {
      state.timerLogoutinterval.remaining = value < 0 ? 0 : value;
    },
    mutateTimerLogoutInterval(state, value: any) {
      clearInterval(state.timerLogoutinterval.interval);
      state.timerLogoutinterval.interval = value;
    },
    mutateTimerLogoutIntervalCheck(state, value: any) {
      clearInterval(state.timerLogoutinterval.intervalCheck);
      state.timerLogoutinterval.intervalCheck = value;
    },
    queueApiCall(state, apiCall: any) {
      state.apiCallsQueue.push(apiCall);
    },
  },
  actions: {
    setTokens(context, value: any) {
      localStorage.setItem('access_token', value.access_token);
      localStorage.setItem('refresh_token', value.refresh_token);
    },
    setRefreshing(context, value: boolean) {
      context.commit('mutateRefreshing', value);
    },
    setRecaptcha(context, token: string) {
      context.commit('mutateRecaptcha', token);
    },
    updateComponent(context) {
      context.commit('mutateUpdateComponent');
    },
    setSimulationLabels(context, value: any) {
      context.commit('mutateLabels', value);
    },
    setSelectedSegment(context, value: Segment) {
      context.commit('mutateSelectedSegment', value);
    },
    setSelectedFinanceType(context, value: FinanceType) {
      context.commit('mutateSelectedFinanceType', value);
    },
    setSelectedProduct(context, value: Product) {
      context.commit('mutateSelectedProduct', value);
    },
    setSelectedSimulation(context, value: Simulation) {
      context.commit('mutateSelectedSimulation', value);

      context.commit('mutateSelectedSegment', value.segment);
      context.commit('mutateSelectedFinanceType', value.finance_type);
      context.commit('mutateSelectedProduct', value.product);
    },
    /* PROGRESS BAR */
    progress(context, value: number) {
      context.commit('mutateProgressValue', value);
    },
    setAfterLogin(context, value: any) {
      context.commit('mutateAfterLogin', value);
    },
    isLoading(context, value: boolean) {
      context.commit('mutateIsLoading', value);
    },
    /* SIDE BAR */
    toggleSideBar(context, value: boolean|null = null) {
      if (window.innerWidth > 700 || value === null || value === false) {
        context.commit('mutateToggleSideBar', value);
      }
    },
    toggleProgressBar(context, value: boolean|null = null) {
      context.commit('mutateToggleVisibleProgressBar', value);
    },
    toggleTopBar(context, value: boolean|null = null) {
      context.commit('mutateToggleTopBar', value);
    },
    /* MODAL */
    modal(context, data: string|ModalData) {
      context.commit('modal', data);
    },
    setDataIntoStep(context, data: StepData) {
      if (data.step in context.state.data) {
        context.commit('mutateDataIntoStep', data);
      }
    },
    setStepAsCompleted(context, data: StepState) {
      if (data.step in context.state.data) {
        context.commit('mutateStepCompleted', data);
        if (data.completed) {
          if (context.state.selected.segment.flows[data.step].previous !== data.step && data.step !== 'Home' && data.direction === 'previous') {
            context.dispatch('setStepAsCompleted', { step: context.state.selected.segment.flows[data.step].previous, completed: true, direction: 'previous' } as StepState);
          }
        } else if (!data.completed) {
          if (context.state.selected.segment.flows[data.step].previous !== data.step && data.step !== 'Home' && data.direction === 'previous') {
            context.dispatch('setStepAsCompleted', { step: context.state.selected.segment.flows[data.step].previous, completed: true, direction: 'previous' } as StepState);
          }

          if (context.state.selected.segment.flows[data.step].next !== data.step && data.step !== 'End') {
            context.dispatch('setStepAsCompleted', { step: context.state.selected.segment.flows[data.step].next, completed: false, direction: 'next' } as StepState);
          }
        }
      }
    },
    /* SEGMENTS ACTIONS */
    setListOfSegments(context, segments: Segment[]) {
      context.commit('mutateListOfSegments', segments);
    },
    /* FINANCE TYPES ACTIONS */
    setListOfFinanceTypes(context, financeTypes: FinanceType[]) {
      context.commit('mutateListOfFinanceTypes', financeTypes);
    },
    /* PRODUCTS ACTIONS */
    setListOfProducts(context, products: Product[]) {
      context.commit('mutateListOfProducts', products);
    },
    /* ACCOUNT ACTIONS */
    setProfile(context, data: Profile) {
      context.commit('mutateProfile', data);
    },
    /* FILE ACTIONS */
    setSelectedFile(context, data: File) {
      context.commit('mutateSelectedFile', data);

      context.commit('mutateSelectedSimulation', data.simulation);
      context.commit('mutateSelectedSegment', data.simulation.segment);
      context.commit('mutateSelectedFinanceType', data.simulation.finance_type);
      context.commit('mutateSelectedProduct', data.simulation.product);
    },
    /* DOCUMENT TYPES ACTIONS */
    setDocumentTypes(context, data: DocumentType[]) {
      context.commit('mutateDocumentTypes', data);
    },
    setConfiguration(context, value: any) {
      context.commit('mutateConfiguration', value);
    },
    initTimerLogoutInterval(context, value: number) {
      context.commit('mutateTimerLogoutIntervalRemaining', value);

      if (context.state.timerLogoutinterval.intervalCheck === null) {
        context.commit('mutateTimerLogoutIntervalCheck', setInterval(() => {
          const remains = Number(context.state.timerLogoutinterval.remaining) as number;

          if (context.state.auth.profile !== undefined && context.state.auth.profile !== null && Object.keys(context.state.auth.profile).length) {
            if (remains === 0) {
              AccountDataService.logout(localStorage.getItem('access_token'));
              context.commit('mutateProfile', null);
              context.commit('modal', 'hide');
            } else if (remains < 30) {
              context.commit('modal', 'hide');
              context.commit('modal', {
                id: 'timer-logout-modal',
                title: 'Important !',
                description: `Vous allez être déconnecté dans <strong>${context.state.timerLogoutinterval.remaining} secondes</strong> pour faute d'inactivité.`,
                actions: {
                  cancel: {},
                  proceed: {},
                },
              } as ModalData);
            } else if (context.state.modal.data.id === 'timer-logout-modal') {
              context.commit('modal', 'hide');
            }
          }
        }, 500));
      }

      if (context.state.timerLogoutinterval.interval === null) {
        context.commit('mutateTimerLogoutInterval', setInterval(() => {
          const remains = Number(context.state.timerLogoutinterval.remaining) as number;

          if (remains > 0) {
            context.commit('mutateTimerLogoutIntervalRemaining', remains - 1);
          }
        }, 1000));
      }
    },
  },
  modules: {
  },
});
