import './assets/styles/main.css';
import { createApp } from 'vue';
import Notifications from '@kyvg/vue3-notification';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';
// import AccountDataService from './services/AccountDataService';

const restrictedRoutes = [
  'KYC',
  'KYCProf',
  'KYCCorp',
  'FinanceInfo',
  'DocumentUpload',
  'RDV',
  'End',
  'CustomerPanel',
  'CustomerFile',
];

const guestRoutes = [
  'Register',
  'Login',
  'EmailHasBeenSent',
  'DefinePassword',
];

/* async function isAuthenticated(): Promise<boolean> {
  const token = store.state.apiToken;
  let isAuthenticatedState = false;

  // TODO: CHECK IF JWT IS VALID AND NOT EXPIRED BEFORE MAKING A REQUEST

  if (token !== null) {
    AccountDataService.checkTokenExpiration(token)
      .then((response: any) => {
        if (response.data.message === true) {
          const refreshToken = localStorage.getItem('refreshToken');

          AccountDataService.generateNewToken(refreshToken).then((result: any) => {
            localStorage.setItem('refreshToken', result.data.refresh_token);

            store.dispatch('setApiToken', result.data.access_token);
            store.dispatch('setProfile', response.data.user);
            store.dispatch('updateComponent');
          });
        }
      });

    await AccountDataService.getProfile(token)
      .then((response: any) => {
        store.dispatch('setProfile', response.data);
        isAuthenticatedState = true;
      }).catch((e: any) => {
        if (e.response.code === 401) {
          store.dispatch('setProfile', null);
          localStorage.removeItem('apiToken');
        }
      });
  }

  // return isAuthenticatedState;
} */

/* router.beforeEach((to, from, next) => {
  if (restrictedRoutes.includes(to.name as string)) {
    isAuthenticated().then((isAuthenticatedState: boolean) => {
      if (!isAuthenticatedState && from.name !== 'LoginBase') next({ name: 'LoginBase' });
      else if (store.state.auth.profile?.email_verified === false && from.name !== 'LoginBase') next({ name: 'LoginBase' });
      else if (from.name !== to.name) next();
    }).catch((e: any) => {
      next({ name: 'SegmentSelection' });
    });
  } else {
    next();
  }

  // else if (guestRoutes.includes(to.name as string)) next({ name: 'Home', params: { segment: from.params?.segment } });
}); */

const vm = createApp(App)
  .use(store)
  .use(router)
  .use(Notifications)
  .use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
    loaderOptions: {
      autoHideBadge: true,
    },
  })
  .use(VueGtag, {
    appName: process.env.VUE_APP_NAME,
    pageTrackerScreenviewEnabled: true,
    config: {
      id: process.env.VUE_APP_GA_MEASUREMENT_ID,
    },
  }, router)
  .mount('#app');

export default vm;
