
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormCheckbox',
  props: {
    name: String,
    placeholder: String,
    default: Boolean,
  },
  emits: ['update:value'],
  data() {
    return {
      checked: false,
    };
  },
  created() {
    this.checked = this.default;
  },
  methods: {
    updateChoice(value: boolean) {
      this.checked = value;
    },
  },
  watch: {
    checked(value, old) {
      this.$emit('update:value', value);
    },
  },
});
