import http from '@/config/http-common';
import Segment from '@/types/Segment';
import FinanceType from '@/types/FinanceType';
import Product from '@/types/Product';

class ProductDataService {
  static getSimulationConfig(product: Product): Promise<any> {
    return http.get(`/products/${product.pid}/config`);
  }

  static getFiltered(segment: Segment, financeType: FinanceType, isCustomer = 'no'): Promise<any> {
    return http.get(`/products?segment=${segment.pid}&finance_type=${financeType.pid}&is_customer=${isCustomer}`);
  }
}

export default ProductDataService;
