import http from '@/config/http-common';
import Segment from '@/types/Segment';

class SegmentDataService {
  static getAll(): Promise<any> {
    return http.get('/segments');
  }

  static download(token: string|null, segment: Segment): Promise<any> {
    return http.get(`/segments/${segment.pid}/download`, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default SegmentDataService;
