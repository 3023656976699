
import { defineComponent } from 'vue';
import globals from '../helpers/globals';

export default defineComponent({
  mixins: [
    globals,
  ],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    logoutNow(event: any) {
      event.preventDefault();
      this.logout(this.$router, this.$store);
    },

  },
});
