import SegmentDataHelper from '@/helpers/SegmentDataHelper';
import FinanceTypeDataService from '@/services/FinanceTypeDataService';
import UndefinedResourceError from '@/types/exceptions/UndefinedResourceError';
import FinanceType from '@/types/FinanceType';
import BasicDataHelper from './BasicDataHelper';

class FinanceTypeDataHelper extends BasicDataHelper {
  static getObjectFromStore(store: any, val: string): FinanceType {
    let ft = {} as FinanceType;

    Object.keys(store.state.financeTypes).every((key: any) => {
      if (val === store.state.financeTypes[key].pid) {
        ft = store.state.financeTypes[key];
        return false;
      }

      return true;
    });

    return ft;
  }

  static prepareComponentData(store: any, segment: string, successCallback: any = (data: any) => { /* */ }, failCallback: any = (e: any) => { /* */ }): void {
    SegmentDataHelper.prepareComponentData(store, (data: any) => {
      const segmentObj = SegmentDataHelper.getObjectFromStore(store, segment);

      if (segmentObj === null || segmentObj === undefined || !Object.keys(segmentObj).length) {
        throw new UndefinedResourceError();
      }

      if (store.state.financeTypes.length === 0) {
        FinanceTypeDataService.getFiltered()
          .then((response: any) => {
            store.dispatch('setListOfFinanceTypes', response.data.data);

            successCallback(response.data.data);
          })
          .catch((e: Error) => {
            failCallback(e);
          });
      } else {
        successCallback(store.state.financeTypes);
      }
    }, (e: Error) => {
      failCallback(e);
    });
  }
}

export default FinanceTypeDataHelper;
