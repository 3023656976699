import SegmentDataService from '@/services/SegmentDataService';
import Segment from '@/types/Segment';
import BasicDataHelper from './BasicDataHelper';

class SegmentDataHelper extends BasicDataHelper {
  static getObjectFromStore(store: any, val: string): Segment {
    let segment = {} as Segment;

    Object.keys(store.state.segments).every((key: any) => {
      if (val === store.state.segments[key].pid) {
        segment = store.state.segments[key];

        return false;
      }

      return true;
    });

    return segment;
  }

  static prepareComponentData(store: any, successCallback: any = (data: any) => { /* */ }, failCallback: any = (e: any) => { /* */ }): void {
    if (store.state.segments.length === 0) {
      SegmentDataService.getAll()
        .then((response: any) => {
          store.dispatch('setListOfSegments', response.data.data);

          successCallback(response.data.data);
        })
        .catch((e: Error) => {
          failCallback(e);
        });
    } else {
      successCallback(store.state.segments);
    }
  }
}

export default SegmentDataHelper;
