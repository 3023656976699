import { AxiosInstance } from 'axios';
import router from '@/router';
import AccountDataService from '@/services/AccountDataService';
import store from '@/store';
import AccountDataHelper from '@/helpers/AccountDataHelper';
import actionHandler from '@/config/action-handler';

let failedQueue = [] as any[];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const errorHandler = (error: any, apiClient: AxiosInstance) => {
  const originalConfig = error.config;

  originalConfig._retry = typeof originalConfig._retry === 'undefined' ? false : originalConfig._retry;

  if ([401, 403].includes(error.response.status) && !originalConfig._retry && error.response.config.url !== 'auth/token/refresh') {
    originalConfig._retry = true;

    if (localStorage.getItem('refresh_token') === null) {
      if (!('actions' in error.response.data) || typeof error.response.data.actions.find((el: any) => el.name === 'redirect') === 'undefined') {
        router.push({
          name: 'LoginBase',
        });

        if ('actions' in error.response.data) {
          actionHandler(error.response.data.actions);
        }

        store.dispatch('setProfile', null);
        return Promise.reject(error);
      }
    } else {
      if (!store.state.refreshing) {
        store.dispatch('setRefreshing', true);

        if ('actions' in error.response.data) {
          actionHandler(error.response.data.actions);
        }

        return new Promise((resolve, reject) => {
          AccountDataService.getNewAccessToken(localStorage.getItem('refresh_token'))
            .then((response: any) => {
              localStorage.setItem('access_token', response.data.data.access_token);
              localStorage.setItem('refresh_token', response.data.data.refresh_token);

              originalConfig.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;

              processQueue(null, response.data.data.access_token);
              resolve(apiClient(originalConfig));

              AccountDataService.getProfile(localStorage.getItem('access_token'))
                .then((responses: any) => {
                  store.dispatch('setProfile', responses.data.data);
                });

              store.dispatch('isLoading', false);
              store.dispatch('setRefreshing', false);
              // return apiClient(originalConfig);
            }).catch((e: any) => {
              store.dispatch('setProfile', null);
              store.dispatch('isLoading', false);
              store.dispatch('setRefreshing', false);

              AccountDataHelper.removeTokens();

              processQueue(e, null);
              reject(e);
            });
        });
      }

      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      }).then(() => {
        originalConfig.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
        return apiClient(originalConfig);
      }).catch((err: any) => err);
    }
  }

  if ([500].includes(error.response.status)) {
    router.push({
      name: 'Error500',
    });
  }

  store.dispatch('isLoading', false);

  if ('actions' in error.response.data) {
    actionHandler(error.response.data.actions);
  }

  return Promise.reject(error);
};

export default errorHandler;
