
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrimaryLink',
  props: {
    caption: String,
    selected: Boolean,
    completed: Boolean,
    isDisabled: Boolean,
  },
  computed: {
    isActive() : boolean {
      return this.selected;
    },
    isCompleted() : boolean {
      return this.completed;
    },
    className(): string {
      let active = '';
      if (this.isCompleted || this.isActive) active = active.concat(' active');
      return active.concat(' transition duration-400 ease-in-out');
    },
  },
});
