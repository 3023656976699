import SegmentDataHelper from '@/helpers/SegmentDataHelper';
import ProductDataService from '@/services/ProductDataService';
import SimulationDataService from '@/services/SimulationDataService';
import UndefinedResourceError from '@/types/exceptions/UndefinedResourceError';
import InputConfig from '@/types/InputConfig';
import BasicDataHelper from './BasicDataHelper';
import FinanceTypeDataHelper from './FinanceTypeDataHelper';
import ProductDataHelper from './ProductDataHelper';

class SimulationDataHelper extends BasicDataHelper {
  static getObjectFromStore(store: any, val: string): InputConfig {
    let input = {} as InputConfig;

    Object.keys(store.state.flow[store.state.currentSegment].steps.Simulation.data.config).every((key: any) => {
      if (val === store.state.flow[store.state.currentSegment].steps.Simulation.data.config[key].name) {
        input = store.state.flow[store.state.currentSegment].steps.Simulation.data.config[key];
        return false;
      }

      return true;
    });

    return input;
  }

  static prepareComponentData(store: any, segment: string, financeType: string, product: string, successCallback: any = (data: any) => { /* */ }, failCallback: any = (e: any) => { /* */ }): void {
    SegmentDataHelper.prepareComponentData(store, (data: any) => {
      const segmentObj = SegmentDataHelper.getObjectFromStore(store, segment);

      FinanceTypeDataHelper.prepareComponentData(store, segmentObj.pid, (dataFt: any) => {
        const financeTypeObj = FinanceTypeDataHelper.getObjectFromStore(store, financeType);

        ProductDataHelper.prepareComponentData(store, segmentObj.pid, financeTypeObj.pid, (dataPr: any) => {
          const productObj = ProductDataHelper.getObjectFromStore(store, product);

          if (productObj === null || productObj === undefined || !Object.keys(productObj).length) {
            throw new UndefinedResourceError();
          }

          if (segmentObj.has_simulation) {
            ProductDataService.getSimulationConfig(productObj)
              .then((response: any) => {
                successCallback(response.data.data);
              })
              .catch((e: Error) => {
                failCallback(e);
              });
          } else {
            successCallback(dataPr);
          }
        }, (e: Error) => {
          failCallback(e);
        });
      }, (e: Error) => {
        failCallback(e);
      });
    }, (e: Error) => {
      failCallback(e);
    });
  }

  static prepareComponentResult(store: any, simulation: string, successCallback: any = (data: any) => { /* */ }, failCallback: any = (e: any) => { /* */ }): void {
    SimulationDataService.getResult(simulation)
      .then((response: any) => {
        const { data } = response.data;
        // store.dispatch('setSimulationData', response.data.inputs);
        // store.dispatch('setSimulationResult', response.data.outputs);
        SegmentDataHelper.prepareComponentData(store, (segmentData: any) => {
          // SegmentDataHelper.getObjectFromStore(store, segment);
        }, (e: Error) => {
          failCallback(e);
        });

        successCallback(data);
      })
      .catch((e: any) => {
        failCallback(e);
      });
  }
}

export default SimulationDataHelper;
