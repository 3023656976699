
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProgressBar',
  props: {
    caption: String,
    selected: Boolean,
  },
  methods: {
    isVisibleProgressBar() {
      return this.$store.state.isVisibleProgressBar;
    },
    progress() {
      return 'width: {progress}%;transition: all 0.8s ease-in-out;'.replace('{progress}', this.$store.state.progress.toString());
    },
  },
});
