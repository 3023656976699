
import { defineComponent } from 'vue';
import PrimaryLink from './PrimaryLink.vue';

export default defineComponent({
  name: 'Sidebar',
  components: {
    PrimaryLink,
  },
  data() {
    return {
      slide: '',
    };
  },
  created() {
    this.slide = window.innerWidth <= 700 ? 'manual-slide' : 'slide';
  },
  methods: {
    isCurrentRoute(names: string[]) {
      const vm = this;
      let found = false;

      Object.values(names).every((value) => {
        if (vm.$route.name === value) {
          found = true;
          return false;
        }

        return true;
      });

      return found;
    },
    goToStep(routeName: string, event: any) {
      event.preventDefault();

      let params: any = {};

      if (routeName === 'IsCustomerSelection') {
        if ('segment' in this.$route.params && !['Register', 'EmailHasBeenSent', 'DefinePassword', 'KYC', 'KYCProf', 'KYCCorp', 'FinanceInfo', 'DocumentUpload', 'RDV', 'End'].includes(this.$route.name as string)) {
          params = {
            segment: this.$route.params.segment,
          };
        }
      } else if (routeName === 'FinanceTypeSelection') {
        if ('segment' in this.$route.params && !['Register', 'EmailHasBeenSent', 'DefinePassword', 'KYC', 'KYCProf', 'KYCCorp', 'FinanceInfo', 'DocumentUpload', 'RDV', 'End'].includes(this.$route.name as string) && ((this.$route.params.segment !== 'part' && localStorage.isCustomer) || this.$route.params.segment === 'part')) {
          params = {
            segment: this.$route.params.segment,
          };
        }
      } else if (routeName === 'ProductSelection') {
        if ('segment' in this.$route.params && 'financeType' in this.$route.params && !['Register', 'EmailHasBeenSent', 'DefinePassword', 'KYC', 'KYCProf', 'KYCCorp', 'FinanceInfo', 'DocumentUpload', 'RDV', 'End'].includes(this.$route.name as string)) {
          params = {
            segment: this.$route.params.segment,
            financeType: this.$route.params.financeType,
          };
        }
      } else if (routeName === 'Simulation') {
        if ('segment' in this.$route.params && 'financeType' in this.$route.params && 'product' in this.$route.params && !['Register', 'EmailHasBeenSent', 'DefinePassword', 'KYC', 'KYCProf', 'KYCCorp', 'FinanceInfo', 'DocumentUpload', 'RDV', 'End'].includes(this.$route.name as string)) {
          params = {
            segment: this.$route.params.segment,
            financeType: this.$route.params.financeType,
            product: this.$route.params.product,
          };
        }
      } else if (['Result'].includes(routeName)) {
        if ('segment' in this.$route.params && 'financeType' in this.$route.params && 'product' in this.$route.params && 'simulation' in this.$route.params && !['Register', 'EmailHasBeenSent', 'DefinePassword', 'KYC', 'KYCProf', 'KYCCorp', 'FinanceInfo', 'DocumentUpload', 'RDV', 'End'].includes(this.$route.name as string)) {
          params = {
            segment: this.$route.params.segment,
            financeType: this.$route.params.financeType,
            product: this.$route.params.product,
            simulation: this.$route.params.simulation ? this.$route.params.simulation : this.$store.state.file.simulation.pid,
          };
        }
      } else if (['Register'].includes(routeName)) {
        if ('segment' in this.$route.params && 'financeType' in this.$route.params && 'product' in this.$route.params && 'simulation' in this.$route.params && !['IsCustomerSelection', 'FinanceTypeSelection', 'ProductSelection', 'Simulation', 'Result', 'End'].includes(this.$route.name as string)) {
          params = {
            segment: this.$route.params.segment,
            financeType: this.$route.params.financeType,
            product: this.$route.params.product,
            simulation: this.$route.params.simulation ? this.$route.params.simulation : this.$store.state.file.simulation.pid,
          };
        }
      } else if (['KYC', 'KYCProf', 'KYCCorp', 'DocumentUpload', 'FinanceInfo', 'RDV', 'End'].includes(routeName)) {
        if ('segment' in this.$route.params && 'financeType' in this.$route.params && 'product' in this.$route.params && 'file' in this.$route.params && !['IsCustomerSelection', 'FinanceTypeSelection', 'ProductSelection', 'Simulation', 'Result', 'End'].includes(this.$route.name as string)) {
          params = {
            segment: this.$route.params.segment,
            financeType: this.$route.params.financeType,
            product: this.$route.params.product,
            file: this.$route.params.file,
          };
        }
      }

      if (Object.keys(params).length) {
        this.$router.push({
          name: routeName,
          params,
        });
      }
    },
    isOpenSidebar() {
      return this.$store.state.isOpenSidebar;
    },
  },
});
