
import { defineComponent } from 'vue';
import globals from '../helpers/globals';
import AccountDropdown from './DropDown.vue';

export default defineComponent({
  components: {
    AccountDropdown,
  },
  name: 'Navbar',
  mixins: [
    globals,
  ],
  methods: {
    toggleSideBar(event: any) {
      event.preventDefault();

      this.$store.dispatch('toggleSideBar');
    },
    isOpenSidebar() {
      return this.$store.state.isOpenSidebar;
    },
    isOpenTopbar() {
      return this.$store.state.isOpenTopbar;
    },
    logoutNow(event: any) {
      event.preventDefault();

      this.logout(this.$router, this.$store);
    },
    goToStart(event: any) {
      event.preventDefault();

      this.$router.push({
        name: 'SegmentSelection',
        params: {},
      });
    },
    goToUserPanel(event: any) {
      event.preventDefault();

      this.$router.push({
        name: 'CustomerPanel',
        params: {},
      });
    },
  },
});
