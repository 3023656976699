import router from '@/router';
import store from '@/store';
import { notify } from '@kyvg/vue3-notification';
import { AxiosResponse } from 'axios';

const successHandler = (response: AxiosResponse) => {
  store.dispatch('isLoading', false);

  if ('actions' in response.data) {
    Object.values(response.data.actions).forEach((action: any) => {
      if (action.name === 'notify') {
        notify({
          text: action.config.text,
          duration: action.config.timeout,
          ignoreDuplicates: true,
          type: action.config.type,
        });
      } else if (action.name === 'redirect' && router.currentRoute.value.name !== action.config.name) {
        router.push({
          name: action.config.name,
          params: action.config.params,
        });
      } else if (action.name === 'invalidate_credentials') {
        store.dispatch('setProfile', null);
        localStorage.removeItem('access_token');

        if (action.config.redirection_to_login_page === true && router.currentRoute.value.name !== 'LoginBase') {
          router.push({
            name: 'LoginBase',
            params: {},
          });
        }
      }
    });
  }

  // return Promise.resolve(response);
  return response;
};

export default successHandler;
