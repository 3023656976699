import axios, { AxiosInstance } from 'axios';
import successHandler from '@/config/success-handler';
import errorHandler from '@/config/error-handler';

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_ENTRYPOINT,
  headers: {
    'Content-type': 'application/json',
    'Client-tiers': 'oca-vue-app',
  },
  timeout: 60000,
});

apiClient.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error, apiClient),
);

export default apiClient;
