
import { defineComponent } from 'vue';
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import FormCheckbox from './forms/Checkbox.vue';

export default defineComponent({
  name: 'Modal',
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    FormCheckbox,
  },
  data() {
    return {
      checkbox: {
        activated: true,
        checked: false,
      },
    };
  },
  methods: {
    hide() {
      this.$store.dispatch('modal', 'hide');
      this.checkbox.checked = false;
    },
    proceed() {
      this.$store.dispatch('modal', 'proceed');
      this.checkbox.checked = false;
    },
  },
});
