import http from '@/config/http-common';
import FinanceType from '@/types/FinanceType';
import Product from '@/types/Product';
import Segment from '@/types/Segment';

class AccountDataService {
  static refreshing = false;

  static testRegister(segment: Segment, simulation: string, form: any): Promise<any> {
    return http.post('/auth/test-register', {
      ...{
        segment: segment.pid,
        simulation,
      },
      ...form,
    });
  }

  static register(simulation: string, form: any): Promise<any> {
    return http.post('/auth/register', {
      ...{
        simulation,
      },
      ...form,
    });
  }

  static login(email: string, password: string, recaptcha: string): Promise<any> {
    return http.post('/auth/login', {
      email,
      password,
      recaptcha,
    });
  }

  static getProfile(token: string|null): Promise<any> {
    return http.get('/auth/account/profile', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static sendConfirmationEmail(token: string|null, simulation: string): Promise<any> {
    return http.post('/auth/email/resend', {
      simulation,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getFiles(token: string|null, segment: string): Promise<any> {
    return http.get(`/auth/account/files?segment=${segment}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getFile(token: string|null, file: string): Promise<any> {
    return http.get(`/auth/account/files/show/${file}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getFirstFile(token: string|null): Promise<any> {
    return http.get('/auth/account/files/first', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static getLastKyc(token: string|null, segment: Segment, financeType: FinanceType, product: Product): Promise<any> {
    return http.get(`/auth/account/kycs/latest?segment=${segment.pid}&finance_type=${financeType.pid}&product=${product.pid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static resetPasswordRequest(email: string, recaptcha: string): Promise<any> {
    return http.post('/auth/reset/password/email', {
      email,
      recaptcha,
    });
  }

  static resetPassword(token: string, email: string, password: string, passwordConfirm: string, recaptcha: string): Promise<any> {
    return http.post('/auth/reset/password/reset', {
      token,
      email,
      password,
      password_confirmation: passwordConfirm,
      recaptcha,
    });
  }

  static logout(token: string|null): Promise<any> {
    return http.post('/auth/logout', {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static checkTokenExpiration(token: string|null): Promise<any> {
    return http.post('/auth/checkToken', {
      token,
    }, {
    });
  }

  static async getNewAccessToken(refreshToken: string|null): Promise<any> {
    return http.post('auth/token/refresh', {
      refreshToken,
    }, {});
  }
}

export default AccountDataService;
