abstract class BasicDataHelper {
  static getObjectFromStore(store: any, val: string): any {
    throw new Error('not implemented !');
  }

  static setAndRedirectToSelectedResource(store: any, param: string, value: string, router: any, redirect: any): void {
    const exists = param in store.state && value in store.state[param].list;

    if (!exists) {
      router.push(redirect);
    }
  }
}

export default BasicDataHelper;
